import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ seo = {}, ogImage = null, themeColor = '#6f46fe' }) => {
  const {
    site: { siteMetadata: seoDefaultSite },
    wp: { seo: seoDefaultWP, allSettings: settingsWP },
  } = useStaticQuery(graphql`
    query SEODefaultQuery {
      site {
        siteMetadata {
          title
          titleTemplate
          description
          url
          image
        }
      }
      wp {
        allSettings {
          generalSettingsDescription
          generalSettingsTitle
        }
        seo {
          schema {
            siteName
            companyName
            companyLogo {
              sourceUrl
              title
            }
          }
          openGraph {
            frontPage {
              description
              title
            }
            defaultImage {
              sourceUrl
            }
          }
        }
      }
    }
  `);

  const seoDefault = {
    title:
      seo.title ||
      seoDefaultWP?.schema?.siteName ||
      settingsWP.generalSettingsTitle ||
      seoDefaultSite.title,
    description:
      seo.metaDesc ||
      settingsWP.generalSettingsDescription ||
      seoDefaultSite.description,
    image:
      seo.opengraphImage?.sourceUrl ||
      seo.image ||
      ogImage ||
      seoDefaultSite.image,
    url: seo.opengraphUrl || seoDefaultSite.url,
  };

  const lang = seoDefault.url.includes('/de/') ? 'de' : 'en';

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={seoDefault.title}
      // titleTemplate={seoDefaultSite.titleTemplate}
    >
      <meta
        name="description"
        content={seo.opengraphDescription || seoDefault.description}
      />
      <meta name="image" content={seoDefault.image} />

      {/* Open Graph */}
      <meta property="og:url" content={seoDefault.url} />
      <meta
        property="og:title"
        content={seo.opengraphTitle || seoDefault.title}
      />
      <meta
        property="og:description"
        content={seo.opengraphDescription || seoDefault.description}
      />
      <meta property="og:image" content={seoDefault.image} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={seo.twitterTitle || seoDefault.title}
      />
      <meta
        name="twitter:description"
        content={
          seo.twitterDescription ||
          seo.opengraphDescription ||
          seoDefault.description
        }
      />
      <meta
        name="twitter:image"
        content={seo.twitterImage?.sourceUrl || seoDefault.image}
      />

      {seo.metaKeywords && <meta name="keywords" content={seo.metaKeywords} />}

      <meta name="theme-color" content={themeColor} />
      <link rel="canonical" href={seoDefault.url} />
    </Helmet>
  );
};

export default SEO;
